.main-bg {
  background-color: #d42020 !important;
}

.main-text {
  color: #d42020;
}

.user-h2 {
  color: #4f575e;
  font-size: 32px;
  text-align: center;
  font-weight: 800;
}

.user-p {
  text-align: center;
  padding: none;
  margin-top: 0;
  font-size: 16px;
  color: #8c868b;
}

.btn {
  background-color: #d42020;
}

.rem {
  font-size: 14px;
  color: #272b30;
  height: 21px;
  line-height: 21px;
  align-items: center;
}

.top-bk {
  color: #d42020;
}

.fp {
  color: #d42020;
  font-size: 14px;
}

.nav-active {
  color: white !important;
  background-color: #d42020 !important;
  border-radius: 10px !important;

}

.nav-active>.navIcon path {
  fill: white !important;
}

.h-screen {
  background-color: #d42020;
}

.card {
  height: 200px !important;
}

.cardWidth {
  width: 625px !important;
}

.cardWidthXL {
  width: 442px !important;
}

/* .camp-card {
  height: 150px !important;
} */

.dash {
  background-color: #f1f1f1;
}

.camp {
  font-size: 33px;
  font-weight: 700;
  line-height: 44.5px;
  color: #4d5562;
}

.camp-header {
  font-size: 16px;
  font-weight: 600px;
  color: #8c868b;
}

.history {
  margin: 30px !important;
  padding: 10px !important;
}

.card-container {
  width: 100%;
  height: 200px;
  margin-bottom: 200px;
  /* Adjust the height to your desired value */
}

.s-btn {
  background-color: #d42020;
  border-radius: 100px;
  width: 100px !important;
  height: 35px;
}

.s-nav {
  border-radius: 100px;
  padding: 4px;
  margin-left: 150px;
}

.camapigns-a {
  color: #d42020;
}

.v-input {
  width: 330px;
}

.v-sub {
  color: #8c868b;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
}

.v-head {
  font-size: 32px;
  color: #4f575e;
  font-weight: 800;
  line-height: 25px;
}

.v-button {
  width: 498.88px;
  height: 48px;
  background-color: #d42020;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .v-button {
    width: 75vw;
    height: 55px;
  }
}

.c-image {
  height: 200px !important;
  width: 100% !important;
  border-radius: 8px;
}

.v-header {
  color: #57535d;
  font-size: 20px;
  font-weight: 700;
}

.v-sub {
  color: #57535d;
  font-size: 12px;
}

.v-body {
  background-color: #f1f1f1;
}

.v-cb {
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.v-date {
  color: #6e6e6e;
  font-weight: 400;
  font-style: normal;
}

.v-desc {
  font-size: 13px;
  color: black;
  line-height: 15px;
  letter-spacing: -0.02em;
}

.v-status {
  color: black;
  font-size: 12px;
  font-weight: 400;
}

.v-progress {
  font-size: 18px;
  font-weight: 700;
  color: #d42020;
}

.cc-header {
  height: 170px !important;
}

.cc-head {
  color: #27252a;
  font-size: 16px;
}

.cc-headtext {
  color: #fff;
  font-size: 32px;
  font-weight: 800;
}

.ac-header {
  font-size: 16px !important;
  color: #333333 !important;
  font-weight: 400;
}

.acc-sub1 {
  font-size: 30x;
  font-weight: 600;
}

.acc-sub {
  font-size: 18px;
  font-weight: 500;
}

.d-amount {
  font-weight: 700;
  font-size: 33px;
  color: #4d5562;
}

.history {
  width: 100%;
}

.whole-bg {
  background-color: #f6f5f6;
}

.dropdown-container {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content button {
  display: block;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: transparent;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #f9f9f9;
}

.nav-active {
  color: white !important;
}

.btn-custom {
  background-color: rgb(231, 231, 231);
  color: #d42020;
  border-radius: 0 !important;
}

.m-input {
  border: 1px dashed lightgrey !important;
}

.ful-screen {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-not {
  height: 400px !important;
}

.spinnner {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.pop-up-container {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.pop-up-div {
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.pop-up-img-container {
  /* border: 1px solid red; */
  width: 150px;
  height: 150px;
}

.pop-up-img-container img {
  width: 100%;
  height: 100%;
}

.pop-up-text-holder {
  text-align: center;
}

.pop-up-text-holder h2 {
  font-size: 50px;
}

.pop-up-text-holder p {
  margin-top: 10px;
  font-size: 20px;
}

.error-title {
  color: red;
}

.success-title {
  color: green;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.in-out {
  background-color: white;
  color: #4d5562;
  border: 1px solid #d42020;
}

.user-container {
  background-color: white;
  width: 800px;
  height: 270px;
  display: flex;
  align-items: center;
  padding: 40px;
  /* justify-content: space-evenly; */
}

.user-container-img {
  width: 150px;
  height: 150px;
  margin-right: 30px;
}

.user-container-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.detail-holder {
  display: flex;
  flex-wrap: wrap;
  width: 590px;
  justify-content: space-evenly;
  /* width: 100%; */
}

.inputHolder {
  display: flex;
  flex-direction: column;
}



.hero-image {
  height: 90vh;
  width: 100%;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Change the color and opacity as desired */
}

.image-container {
  position: relative;
  width: 300px;
  /* Set the desired width */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Change the color and opacity as desired */
}


.lp-header {
  font-size: 48px !important;
  font-weight: bolder !important;
  color: #6B0B69;
}

.lp-header-span {
  color: #333333;
}

.select-lp {
  background-color: #F6F5F6;
  font-weight: bold;
}

/* Add this to your CSS file or use inline styles in React */
.pagination-btn {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  margin: 0 4px;
}

.pagination-list {
  display: flex;
  align-items: center;
}

.pagination-number {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  margin: 0 4px;
}

.pagination-number.active {
  background-color: #d42020;
  color: #fff;
}